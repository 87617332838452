@import './src/assets/css/_variables.scss';

.Tool {
    img {
        border: 1px solid lightgrey;
        border-radius: 100%;
        cursor: pointer;
    }

	.borderBottom {
		border-bottom: 1px solid white;
		border-left: 40px solid black;
		border-right: 40px solid black;
		padding: 0 5px;
	}

    .Tool-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        > div {
            flex: 1 0 23%;

            .dropdown {
                position: relative;
                display: flex;
                justify-content: center;
                cursor: default;
            }
        }

        h4 {
            text-align: center;
            justify-content: center;
        }
    }
    
    .dropdown-content {
        justify-content: center;
        position: absolute;
        background-color: #242424;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
        transition: width 2s;
        transition-duration: 5s;
        border-radius: 4rem;
        z-index: 10000;
        top: 100%;
    }

    .desc {
        padding: 15px;
        text-align: center;
        color: $white;
    }

    .dropdown:hover .dropdown-content {
        display: flex;
        width: 100%;
    }

    @media (max-width: 991px) {
        .Tool-wrapper {
            display: block;

            img {
                align-self: center;
            }

            .dropdown {
                flex-direction: column;
            }
    
            .dropdown-content {
                display: flex!important;
                position: relative;
                min-width: 100%;
                margin: 1rem 0;
            }
        }
    }
}