$viewport-extra-small: 48em / 1.6;

.Navigation {
    #navbar {
        z-index: 1030;
        transition: all 0.5s;
        position: fixed;
        top: 5px;
        right: 5px;
        white-space: nowrap;
        padding-top: 50px;
        height: 50px;
        overflow: hidden;
        scrollbar-width: none;
        width: 50px;
        background: linear-gradient(180deg, #2d769c, #5a0069);
        border-radius: 25px;

        > div {
            cursor: pointer;
        }

        i {
            padding-left: 10px;
            transition: all 0.4s ease-in-out;
        }
    }

    .navbar-nav {
        display: none;
    }

    #navigation {
        opacity: 1 !important;
        position: absolute;
        top: 0;
        left: 0;
    }

    .nav-link {
        color: #fefdf8;

        &:hover {
            color: #f1f1f1;
        }
    }

    .main-menu {
        font-size: 36px;
        color: #000;
        position: absolute;
        top: 7px;
        transition: all 0.4s ease-in-out;
        right: 10px;
    }

    .open {
        width: 400px !important;
        background: linear-gradient(180deg, #2d769c, #5a0069);
        height: 100vh !important;
        padding-left: 10px;
        border-radius: 25px 0 0 0!important;
        padding-top: 60px !important;
        top: 0 !important;
        right: 0 !important;

        > div {
            transition: all 0.4s ease-in-out;
            padding-left: 5px;
            justify-content: flex-start !important;

            i {
                top: 15px;
                left: 13px;
                transform: rotate(270deg);

                &:before {
                    content: '\f00d';
                }
            }
        }

        #navigation {
            top: 5px;
        }

        .navbar-items {
            font-size: 1.25rem;
            display: flex;
        }
    }

    img {
        height: 50px;
    }

    @media (max-width: $viewport-extra-small) {
        .open {
            width: 100% !important;
        }
    }
}
