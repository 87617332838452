@import './src/assets/css/_variables.scss';

.Admin {
    &-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        div {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: column;
            margin: .5rem;
        }
    }

    &-streamer {
        display: flex;
        justify-content: center;
        margin: 0 auto!important;
    }

    &-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    input {
        margin-bottom: 1rem;
    }

    button {
        margin: 1rem 0;
    }
}