@font-face {
  font-family: 'Old London';
  src: url('../fonts/Old London.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Old London Alternate';
  src: url('../fonts/Old London Alternate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}