.Designer {
	.logo {
	}

	.cartoon-logo {
	}

	img {
		border: 1px solid lightgrey;
		border-radius: 100%;
		cursor: pointer;
	}
}
