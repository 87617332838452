@import 'variables';
@import 'font';

body {
    color: $white;
    background-color: $black;
    overflow-x: hidden;
    position: relative;
    height: 100%;

    h1 {
        font-family: $base-font;
    }

    a {
        text-decoration: none !important;
        cursor: pointer;
    }

    #mainContent {
        min-height: 100vh;
        padding-bottom: 100px;
    }
}
